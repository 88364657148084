body {
  background: #000;
}
.App {
  padding-top: 64px;
  background: #000;
}
.App-header {
  border-bottom: 0px solid #eee;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  z-index: 10;
}
.App-nav {
  margin: 1rem 1.5rem;
}
.App-nav a {
  display: inline-block;
  color: #555555;
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  margin-right: 2rem;
  text-decoration: none;
}
.App-nav a.active {
  color: #1cde78;
}

.App-loading-indicator {
  position: fixed;
  height: 4px;
  top: 0;
  left: 0;
  right: 0;
  background-color: #1cde78;
  background-image: linear-gradient(-45deg, rgba(255,255,255,0.25) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.25) 50%, rgba(255,255,255,0.25) 75%, transparent 75%, transparent);
  background-size: 35px 35px;
  z-index: 1000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) inset;
  transform: translateY(-4px);
  transition: transform ease-in 300ms, opacity ease-in 300ms;
  transition-delay: 0;
  animation: App-loading 2s cubic-bezier(.4,.45,.6,.55) infinite;
  opacity: 0;
}

.App-loading-indicator.active {
  transition-delay: 333ms;
  transform: translateY(0);
  opacity: 1;
}

@keyframes App-loading {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -35px -35px;
  }
}